import { Text } from '@chakra-ui/react';
import { Select, SingleValue } from 'chakra-react-select';

import { SelectItem } from '@/components/form/select-form';
import { isNil } from '@/utils/guard';

type ImageGenerateSelect = {
  label: string;
  options: SelectItem[];
  onSelect: (item: string) => void;
  value: string;
};

export const ImageGenerateSelect = ({
  label,
  options,
  onSelect,
  value,
}: ImageGenerateSelect) => {
  const handleSelect = (selected: SingleValue<SelectItem>) => {
    if (isNil(selected)) {
      return;
    }

    const item = options.find(s => s.value === selected.value);
    if (item) {
      onSelect && onSelect(item.value);
    }
  };

  return (
    <>
      <Text size="md">{label}</Text>
      <Select
        name={'name'}
        options={options}
        value={options.find(opt => opt.value === value)}
        menuPortalTarget={document.body}
        closeMenuOnScroll={true}
        onChange={handleSelect}
        styles={{
          menuPortal: provided => ({
            ...provided,
            zIndex: 1500,
          }),
        }}
        chakraStyles={{
          placeholder: provided => ({
            ...provided,
            color: 'grey.400',
          }),
          control: provided => ({
            ...provided,
            minHeight: 10,
            borderRadius: 10,
            border: '1px',
            background: 'white',
            color: 'grey.700',
            _focus: {
              color: 'grey.900',
            },
            _invalid: {
              boxShadow: 'none',
              borderColor: 'error.500',
            },
          }),
          menuList: provided => ({
            ...provided,
            borderRadius: 10,
          }),
        }}
        focusBorderColor="primary.500"
        selectedOptionStyle="check"
        menuPlacement="auto"
        useBasicStyles
        isSearchable={false}
      />
    </>
  );
};

import {
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Spacer,
  useDisclosure,
} from '@chakra-ui/react';
import { useShallow } from 'zustand/react/shallow';

import { ReactNode } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { requestUpdateSettlement } from '@/api/payday';
import { useStartGlobalLoading } from '@/hooks/use-start-global-loading';
import { TDS } from '@/libs/chakra';
import { useSettleStore } from '@/stores/settle';
import { useUserStore } from '@/stores/user';
import { isSuccess } from '@/utils/guard';
import { z, zodResolver } from '@/utils/validator';

import { AccountFormSchema, TaxFormSchema } from '../schema';
import { settleMonth } from '../utils/settle-month';
import { SettlementAccountForm } from './settlement-account-form';
import { SettlementTaxFilingForm } from './settlement-tax-filing-form';

const STEP = ['ACCOUNT_FORM', 'FAX_FORM'] as const;

export function SettlementInputModal({
  dataProviderId,
  isFormComplete,
  isOpen,
  onClose: _onClose,
}: {
  dataProviderId: string;
  isFormComplete: boolean;
  isOpen: boolean;
  onClose: () => void;
}) {
  const { t } = useTranslation('settlePage');
  const { user } = useUserStore(useShallow(state => ({ user: state.user })));
  const { formData, setSettle, setIsFormComplete } = useSettleStore(
    useShallow(state => ({
      formData: state.formData,
      setSettle: state.setSettle,
      setIsFormComplete: state.setIsFormComplete,
    })),
  );
  const updateNoticeModal = useDisclosure();
  const startGlobalLoading = useStartGlobalLoading();
  const inputModalInitialRef = useRef<HTMLButtonElement>(null);
  const inputModalFinalRef = useRef<HTMLButtonElement>(null);
  const updateNoticeModalInitialRef = useRef<HTMLButtonElement>(null);

  const [step, setStep] = useState<(typeof STEP)[number]>('ACCOUNT_FORM');
  const accountForm = useForm<z.infer<typeof AccountFormSchema>>({
    mode: 'onChange',
    resolver: zodResolver(AccountFormSchema),
    defaultValues: {
      accountOwner: formData.accountOwner,
      bank: formData.bank,
      accountNumber: formData.accountNumber,
      bankBook: formData.bankBook,
    },
    shouldFocusError: true,
  });

  const taxForm = useForm<z.infer<typeof TaxFormSchema>>({
    mode: 'onChange',
    resolver: zodResolver(TaxFormSchema),
    defaultValues: {
      name: formData.name,
      ssn: formData.ssn,
      address: formData.address,
      detailAddress: formData.detailAddress,
      isKoreanResident: formData.isKoreanResident,
      phoneNumber: formData.phoneNumber,
      email: formData.email || user?.email,
      isUserResponsible: formData.isUserResponsible,
      residenceCard: formData.residenceCard,
    },
    shouldFocusError: true,
  });

  const undoStep = () => {
    setStep('ACCOUNT_FORM');
    setSettle(taxForm.getValues());
  };

  const onClose = () => {
    setSettle({ ...accountForm.getValues(), ...taxForm.getValues() });
    _onClose();
  };

  const nextStep1: SubmitHandler<z.infer<typeof AccountFormSchema>> = () => {
    setSettle({ ...accountForm.getValues(), ...taxForm.getValues() });
    if (taxForm.getValues().name === undefined) {
      taxForm.setValue('name', accountForm.getValues().accountOwner);
    }
    setStep('FAX_FORM');
    if (isFormComplete) {
      taxForm.trigger();
    }
  };

  const nextStep2: SubmitHandler<z.infer<typeof TaxFormSchema>> = () => {
    setSettle({ ...accountForm.getValues(), ...taxForm.getValues() });
    updateNoticeModal.onOpen();
  };

  const onSubmit = async () => {
    await startGlobalLoading(async () => {
      const { accountOwner, bank, accountNumber, bankBook } =
        accountForm.getValues();
      const {
        name,
        ssn,
        address,
        detailAddress,
        phoneNumber,
        email,
        residenceCard,
      } = taxForm.getValues();
      const updateSettlement = await requestUpdateSettlement({
        dataProviderId,
        accountOwner,
        bank,
        accountNumber: accountNumber.number,
        bankBook: bankBook[0],
        name,
        ssnFront: ssn.front,
        ssnBack: ssn.back,
        address,
        detailAddress,
        phoneNumber,
        email,
        residenceCard: residenceCard[0],
      });
      if (isSuccess(updateSettlement)) {
        setIsFormComplete(true);
        updateNoticeModal.onClose();
        _onClose();
        return;
      }
    });
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        initialFocusRef={inputModalInitialRef}
        finalFocusRef={inputModalFinalRef}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          rounded="2xl"
          width="full"
          maxWidth={500}
          marginX={5}
          paddingY={4}
          paddingX={6}
        >
          <ModalHeader paddingY={3.5} paddingX={0}>
            <Flex justify="space-between">
              <TDS.Typo as="h5" size="xl" weight="bold">
                {step === 'ACCOUNT_FORM'
                  ? t('input-modal.step.settlement-account-info')
                  : t('input-modal.step.settlement-tax-filing-info')}
              </TDS.Typo>
              <TDS.Typo as="p" size="xl" weight="bold">
                {STEP.indexOf(step) + 1}/2
              </TDS.Typo>
            </Flex>
          </ModalHeader>
          <ModalBody paddingY={4} paddingX={0}>
            {step === 'ACCOUNT_FORM' ? (
              <form onSubmit={accountForm.handleSubmit(nextStep1)}>
                <SettlementAccountForm form={accountForm} />
                <Flex pt={6} gap={2} alignItems="center">
                  <Spacer />
                  <TDS.Button
                    size="md"
                    colorScheme="grey"
                    onClick={onClose}
                    ref={inputModalFinalRef}
                  >
                    {t('cancel')}
                  </TDS.Button>
                  <TDS.Button
                    type="submit"
                    size="md"
                    isDisabled={!accountForm.formState.isValid}
                    ref={inputModalInitialRef}
                  >
                    {t('next')}
                  </TDS.Button>
                </Flex>
              </form>
            ) : (
              <form onSubmit={taxForm.handleSubmit(nextStep2)}>
                <SettlementTaxFilingForm form={taxForm} />
                <Flex pt={6} gap={2} alignItems="center">
                  <TDS.Typo as="p" size="md" weight="semibold">
                    <TDS.Button
                      variant="link"
                      colorScheme="grey"
                      onClick={undoStep}
                      cursor="pointer"
                      size="md"
                      padding={0}
                      leftIcon={
                        <i className="i-t-chevron-right scale-x--100 scale-y-100 text-6" />
                      }
                    >
                      {t('undo')}
                    </TDS.Button>
                  </TDS.Typo>
                  <Spacer />
                  <TDS.Button
                    size="md"
                    colorScheme="grey"
                    onClick={onClose}
                    ref={inputModalFinalRef}
                  >
                    {t('cancel')}
                  </TDS.Button>
                  <TDS.Button
                    type="submit"
                    size="md"
                    isDisabled={!taxForm.formState.isValid}
                    ref={inputModalInitialRef}
                  >
                    {t('complete')}
                  </TDS.Button>
                </Flex>
              </form>
            )}
          </ModalBody>
        </ModalContent>
      </Modal>

      <Modal
        isOpen={updateNoticeModal.isOpen}
        onClose={updateNoticeModal.onClose}
        initialFocusRef={updateNoticeModalInitialRef}
        isCentered
      >
        <ModalOverlay />
        <ModalContent
          rounded="2xl"
          width="full"
          maxWidth={448}
          marginX={5}
          paddingY={4}
          paddingX={6}
        >
          <UpdateNotice>
            <Flex pt={6} gap={2} alignItems="center">
              <Spacer />
              <TDS.Button
                size="md"
                colorScheme="grey"
                onClick={updateNoticeModal.onClose}
              >
                {t('cancel')}
              </TDS.Button>
              <TDS.Button
                type="button"
                size="md"
                onClick={onSubmit}
                ref={updateNoticeModalInitialRef}
              >
                {t('complete')}
              </TDS.Button>
            </Flex>
          </UpdateNotice>
        </ModalContent>
      </Modal>
    </>
  );
}

function UpdateNotice({ children }: { children: ReactNode }) {
  const { t } = useTranslation('settlePage');
  const month = settleMonth();

  return (
    <>
      <ModalHeader paddingY={3.5} paddingX={0}>
        <TDS.Typo as="h5" size="lg" weight="bold">
          {t('input-modal.settlement-info-update-notice.header')}
        </TDS.Typo>
      </ModalHeader>
      <ModalBody paddingY={4} paddingX={0}>
        <TDS.Typo size="md" color="grey.700">
          {t('input-modal.settlement-info-update-notice.body', {
            month,
          })}
        </TDS.Typo>
        {children}
      </ModalBody>
    </>
  );
}

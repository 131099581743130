import * as Sentry from '@sentry/react';
import { useShallow } from 'zustand/react/shallow';

import { PropsWithChildren } from 'react';

import { ScreenLoading } from '@/components/screen-loading';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  TDS,
} from '@/components/ui';
import { useAuthenticationContext } from '@/hooks/use-authentication-context';
import { useLoadingStore } from '@/stores/loading';

import { Fallback } from '../fallback';

type BaseLayoutProps = PropsWithChildren;

export function BaseLayout({ children }: BaseLayoutProps) {
  const { isLoading } = useLoadingStore(useShallow(state => state));
  const { isShowAutoSigninWarning, hideAutoSigninWarning } =
    useAuthenticationContext();

  return (
    <Sentry.ErrorBoundary fallback={Fallback}>
      <>
        {children}
        <ScreenLoading show={isLoading} />
        <Modal
          isOpen={isShowAutoSigninWarning}
          onClose={hideAutoSigninWarning}
          isCentered
        >
          <ModalOverlay />
          <ModalContent
            rounded="2xl"
            width="full"
            maxWidth={448}
            marginX={5}
            paddingX={6}
          >
            <ModalHeader
              fontSize="lg"
              fontWeight="bold"
              paddingY={4}
              paddingX={0}
            >
              자동 로그인 안내
            </ModalHeader>
            <ModalBody paddingY={2} paddingX={0}>
              <TDS.Typo
                as="p"
                size="md"
                color="grey.700"
                className="whitespace-pre-wrap"
              >
                이미 타입캐스트에 로그인 되어 있어서 해당 계정으로 자동 로그인
                되었습니다.
              </TDS.Typo>
            </ModalBody>
            <ModalFooter paddingY={4} paddingX={0} gap={3}>
              <TDS.Button
                size="md"
                colorScheme="primary"
                onClick={hideAutoSigninWarning}
              >
                확인
              </TDS.Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    </Sentry.ErrorBoundary>
  );
}

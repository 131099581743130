import { ComponentProps } from 'react';
import { NavLink, useMatches } from 'react-router-dom';

import { TDS, Spacer, TDSButtonProps } from '@/components/ui';
import { TYPECAST_APP } from '@/constants/external-link';
import { useRole } from '@/hooks/use-role';
import { cn } from '@/utils/cn';

import { Logo } from '../logo';
import { Profile } from '../profile';

export type SideNavigationBarProps = ComponentProps<'nav'>;

export function SideNavigationBar({
  className,
  ...props
}: SideNavigationBarProps) {
  const matches = useMatches();
  const currentPath = matches.at(-1)?.pathname;
  const { isNormalProvider } = useRole();

  const isPartnerPage =
    currentPath !== '/partners/settle' && currentPath?.startsWith('/partners');
  const isSettlePage = currentPath === '/partners/settle';
  return (
    <nav
      className={cn(
        'flex flex-col justify-between',
        'py-8 px-4 bg-snb-background color-snb-foreground',
        className,
      )}
      {...props}
    >
      <Logo className="mb-8" />
      {!isNormalProvider && (
        <RouteLink to="/partners" isActive={isPartnerPage}>
          <i className="i-t-character text-6" />
          <TDS.Typo weight={isPartnerPage ? 'bold' : 'medium'}>
            캐릭터 관리
          </TDS.Typo>
        </RouteLink>
      )}
      <RouteLink to="/partners/settle" isActive={isSettlePage}>
        <i className="i-t-wallet text-6" />
        <TDS.Typo weight={isSettlePage ? 'bold' : 'medium'}>정산 내역</TDS.Typo>
      </RouteLink>
      <Spacer />
      <ExternalLink to={TYPECAST_APP} className="mb-6 gap-1">
        타입캐스트로 이동
        <i className="i-t-link text-4" />
      </ExternalLink>
      <Profile />
    </nav>
  );
}

function RouteLink({
  to,
  className,
  isActive,
  ...props
}: Omit<ComponentProps<typeof NavLink>, 'className'> & {
  className?: string;
  isActive?: boolean;
}) {
  return (
    <NavLink
      to={to}
      className={() =>
        cn(
          isActive ? 'bg-grey-100' : 'bg-transparent color-grey-600',
          'rounded-lg h-12 flex flex-row gap-1 items-center px-4 text-md',
          className,
        )
      }
      {...props}
    ></NavLink>
  );
}

function ExternalLink({
  to,
  className,
  children,
  ...props
}: TDSButtonProps & { to: string }) {
  return (
    <TDS.Button
      as="a"
      variant="outline"
      colorScheme="grey"
      size="sm"
      href={to}
      target="_blank"
      rel="noreferrer"
      className={className}
      {...props}
    >
      {children}
    </TDS.Button>
  );
}

import { isValidFileType } from '@/utils';
import { z } from '@/utils/validator';

export const ACCEPTED_IMAGE_TYPES = ['image/png', 'image/jpeg'];

const validateImageFile = (files: File[], ctx: z.RefinementCtx) => {
  const file = files[0];

  if (!file) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: '이미지 파일을 입력해 주세요',
    });
    return;
  }

  if (!isValidFileType(ACCEPTED_IMAGE_TYPES, file)) {
    ctx.addIssue({
      code: z.ZodIssueCode.custom,
      message: '잘못된 파일을 업로드 하였습니다. 다른 파일로 시도해 주세요.',
    });
    return;
  }
};

const koreanRegex = /^[가-힣]+$/;

export const AccountFormSchema = z
  .object({
    accountOwner: z
      .string()
      .min(1, { message: '예금주는 1글자 이상 필요합니다.' })
      .regex(koreanRegex, { message: '올바르지 못한 입력값입니다.' }),
    bank: z.string().min(1, { message: '은행을 지정해 주세요.' }),
    accountNumber: z
      .object({
        number: z
          .string()
          .min(1, { message: '계좌번호는 1글자 이상 필요합니다.' }),
        confirmNumber: z.string().min(1, ''),
      })
      .refine(
        data => {
          if (data.number === data.confirmNumber) {
            return true;
          }
          return false;
        },
        { message: '계좌번호가 일치하지 않습니다.' },
      ),
    bankBook: z
      .custom<File[]>()
      .superRefine(async (files, ctx) => validateImageFile(files, ctx)),
  })
  .required({
    accountOwner: true,
    bank: true,
    accountNumber: true,
    bankBook: true,
  });

const frontRegex = /^(?:[0-9]{2})(?:0[1-9]|1[0-2])(?:0[1-9]|[12][0-9]|3[01])$/;
const backRegex = /^[1-4][0-9]{6}$/;
const phoneNumberRegex = /^[0][1][0][0-9]{8}$/;

export const TaxFormSchema = z
  .object({
    name: z
      .string()
      .min(1, { message: '이름은 1글자 이상 필요합니다.' })
      .regex(koreanRegex, { message: '올바르지 못한 입력값입니다.' }),
    ssn: z
      .object({
        front: z.string().regex(frontRegex, {
          message: '주민등록번호 앞자리 형식이 잘못되었습니다.',
        }),
        back: z.string().regex(backRegex, {
          message: '주민등록번호 뒷자리 형식이 잘못되었습니다.',
        }),
      })
      .refine(
        data => {
          if (data.front.length === 6 && data.back.length === 7) {
            return true;
          }
          return false;
        },
        { message: '주민등록번호 13자리를 입력해 주세요.' },
      ),
    address: z.string().min(1, { message: '주소는 1글자 이상 필요합니다.' }),
    detailAddress: z
      .string()
      .min(1, { message: '상세주소는 1글자 이상 필요합니다.' }),
    isKoreanResident: z.literal(true),
    phoneNumber: z
      .string()
      .length(11, { message: '휴대폰 번호 11자리를 입력해 주세요.' })
      .regex(phoneNumberRegex, {
        message: '핸드폰 번호 형식이 잘못되었습니다.',
      }),
    email: z.string().email({ message: '이메일 형식을 입력해 주세요.' }),
    isUserResponsible: z.literal(true),
    residenceCard: z
      .custom<File[]>()
      .superRefine(async (files, ctx) => validateImageFile(files, ctx)),
  })
  .required({
    name: true,
    address: true,
    isKoreanResident: true,
    ssn: true,
    phoneNumber: true,
    email: true,
    isUserResponsible: true,
    residenceCard: true,
  });

import { useController, useFormContext } from 'react-hook-form';

import { FormControl, FormErrorMessage, TDS, Divider } from '@/components/ui';
import { CHARACTER_TERM, SERVICE_TERM } from '@/constants/external-link';
import { isFalse, isTrue } from '@/utils/guard';

type TermsFieldProps = {
  disabled?: boolean;
  readonly?: boolean;
};

export function TermsField({ disabled, readonly, ...props }: TermsFieldProps) {
  const { control } = useFormContext();
  const term1Id = `${useId()}-term1-field`;
  const term2Id = `${useId()}-term2-field`;
  const term3Id = `${useId()}-term3-field`;
  const term4Id = `${useId()}-term4-field`;
  const termsAllId = `${useId()}-all-field`;
  const {
    field: { name, value, ref, onChange },
    fieldState: { error },
  } = useController({
    control,
    name: 'terms',
    rules: { required: true },
    defaultValue: {
      term1: false,
      term2: false,
      term3: false,
      term4: false,
    },
  });
  const isAllChecked = Object.values(value).every(isTrue);
  const isAllUnchecked = Object.values(value).every(isFalse);
  const isIndeterminated = !(isAllChecked || isAllUnchecked);

  return (
    <FormControl {...props}>
      <div className="flex flex-col gap-2.5 color-grey-700 text-sm leading-normal">
        <div className="flex flex-row gap-1.5 items-center">
          <TDS.Checkbox
            id={termsAllId}
            name={name}
            ref={ref}
            isIndeterminate={isIndeterminated}
            isChecked={isAllChecked}
            isReadOnly={readonly}
            isDisabled={disabled}
            onChange={() => {
              onChange({
                term1: !isAllChecked,
                term2: !isAllChecked,
                term3: !isAllChecked,
                term4: !isAllChecked,
              });
            }}
          >
            <span className="text-sm leading-normal color-grey-900">
              아래 사항에 모두 동의합니다.
            </span>
          </TDS.Checkbox>
        </div>
        <Divider className="my-4" />
        <ul>
          <li className="flex flex-row gap-1.5 items-start mb-3">
            <TDS.Checkbox
              id={term1Id}
              size="md"
              name={name}
              alignItems="flex-start"
              isChecked={value.term1}
              isReadOnly={readonly}
              isDisabled={disabled}
              onChange={() => {
                onChange({ ...value, term1: !value.term1 });
              }}
            >
              <span className="text-sm leading-normal">
                이용자의 목소리가 아닌, 제 3자의 음성 및 이미지 데이터를
                사용하여 업로드 할 경우, 이용자는 해당 음성 및 이미지 파일에
                대한 법적인 이용 권한이 이용자에게 있음을 확인합니다.
              </span>
            </TDS.Checkbox>
          </li>
          <li className="flex flex-row gap-1.5 items-start mb-3">
            <TDS.Checkbox
              id={term2Id}
              size="md"
              alignItems="flex-start"
              isChecked={value.term2}
              isReadOnly={readonly}
              isDisabled={disabled}
              onChange={() => {
                onChange({ ...value, term2: !value.term2 });
              }}
            >
              <span className="text-sm leading-normal">
                출시 요청을 진행한 캐릭터의 출시 결과는 타입캐스트 내부 정책에
                따라 승인 및 반려될 수 있습니다.
              </span>
            </TDS.Checkbox>
          </li>
          <li className="flex flex-row gap-1.5 items-start mb-3">
            <TDS.Checkbox
              id={term3Id}
              size="md"
              alignItems="flex-start"
              isChecked={value.term3}
              isReadOnly={readonly}
              isDisabled={disabled}
              onChange={() => {
                onChange({ ...value, term3: !value.term3 });
              }}
            >
              <span className="text-sm leading-normal">
                <a
                  href={SERVICE_TERM}
                  target="_blank"
                  rel="noreferrer"
                  className="underline color-black"
                  style={{
                    textUnderlinePosition: 'under',
                  }}
                >
                  서비스 이용약관[링크]
                </a>
                에 동의하며 보이스허브 정책은 당사 방침에 따라 사전 고지 후
                변경될 수 있습니다.
              </span>
            </TDS.Checkbox>
          </li>
          <li className="flex flex-row gap-1.5 items-start">
            <TDS.Checkbox
              id={term4Id}
              size="md"
              alignItems="flex-start"
              isChecked={value.term4}
              isReadOnly={readonly}
              isDisabled={disabled}
              onChange={() => {
                onChange({ ...value, term4: !value.term4 });
              }}
            >
              <span className="text-sm leading-normal">
                <a
                  href={CHARACTER_TERM}
                  target="_blank"
                  rel="noreferrer"
                  className="underline color-black "
                  style={{
                    textUnderlinePosition: 'under',
                  }}
                >
                  캐릭터 출시를 위한 계약서[링크]
                </a>
                에 동의합니다.
              </span>
            </TDS.Checkbox>
          </li>
        </ul>
      </div>
      <FormErrorMessage>{error?.message}</FormErrorMessage>
    </FormControl>
  );
}

import { useTranslation } from 'react-i18next';

import { GetDataProviderProfitsResponseDTO } from '@/api/types/payday';
import {
  Flex,
  Skeleton,
  Spacer,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from '@/components/ui';
import { TDS } from '@/libs/chakra';
import { CharacterProfit, ProfitRow } from '@/types/profits';
import { formatToKRW } from '@/utils/format-currency';
import { getPreviousMonthStartAndEnd, dayjs } from '@/utils/time';

import { SettlementPagination } from './settlement-pagination';
import { SettlementRevenue } from './settlement-revenue';

export type Period = {
  startDate: string;
  endDate: string;
};

type SettlementTableProps = {
  profit: GetDataProviderProfitsResponseDTO;
  profitRows?: ProfitRow[];
  pageIndex: number;
  pageLimit: number;
  onClickPrev: (index: number) => void;
  onClickNext: (index: number, quotient: number) => void;
  onClickPage: (num: number) => void;
  onClickMonthRevenue: (
    date: Period,
    item: CharacterProfit[],
    totalRevenue: number,
  ) => void;
};

export function SettlementTable({
  profit,
  profitRows,
  pageIndex,
  pageLimit,
  onClickPrev,
  onClickNext,
  onClickPage,
  onClickMonthRevenue,
}: SettlementTableProps) {
  return (
    <>
      <Flex flexDirection="column" rowGap={3}>
        <SettledTable
          profitRows={profitRows}
          onClickMonthRevenue={onClickMonthRevenue}
          pageLimit={pageLimit}
        />
        <SettlementPagination
          index={pageIndex}
          total={profit.profit_rows.total}
          size={pageLimit}
          onClickPrev={onClickPrev}
          onClickNext={onClickNext}
          onClickPage={onClickPage}
        />
      </Flex>
    </>
  );
}

function SettledTable({
  profitRows: data,
  onClickMonthRevenue,
  pageLimit,
}: {
  profitRows?: ProfitRow[];
  pageLimit: number;
  onClickMonthRevenue: (
    date: Period,
    item: CharacterProfit[],
    totalRevenue: number,
  ) => void;
}) {
  const { t } = useTranslation('settlePage');
  const theadKeys = [
    t('table.date'),
    t('table.previous-month-cumulative-revenue'),
    t('table.current-month-revenue'),
    t('table.current-month-cumulative-revenue'),
    t('table.current-month-settlement-revenue'),
  ] as const;

  return (
    <TableContainer borderRadius={10}>
      <Table className="table-fixed">
        <Thead bgColor="grey.700">
          <Tr>
            {theadKeys.map((key, i) => (
              <Th isNumeric={i !== 0} key={key} p="11px 24px">
                <TDS.Typo size="xs" weight="bold" color="white">
                  {key}
                </TDS.Typo>
              </Th>
            ))}
          </Tr>
        </Thead>
        <Tbody bgColor="white">
          {(() => {
            if (!data)
              return (
                <>
                  {Array.from({ length: pageLimit }).map((_, i) => (
                    <Tr key={`SettlementTable_Tbody_Skeleton_${i}`}>
                      <TdSkeleton />
                    </Tr>
                  ))}
                </>
              );

            return (
              <>
                {data.map((item, i) => {
                  const date = dayjs(item.target_date);

                  const year = date.year();
                  const month = date.month() + 1;

                  const previousDate = getPreviousMonthStartAndEnd(date);

                  const startDate = previousDate.startDate
                    .split(' ')
                    .slice(1)
                    .join(' ');
                  const endDate = previousDate.endDate
                    .split(' ')
                    .slice(1)
                    .join(' ');

                  return (
                    <Tr key={`SettlementTable_Tbody_${i}`}>
                      <Td p="8.5px 24px">
                        <Flex flexDirection="column">
                          <TDS.Typo size="sm" weight="medium" color="black">
                            {year}년 {month}월
                          </TDS.Typo>
                          <TDS.Typo size="xs" weight="normal" color="grey.700">
                            ({startDate} ~ {endDate})
                          </TDS.Typo>
                        </Flex>
                      </Td>
                      <Td isNumeric>
                        <TDS.Typo size="sm" color="grey.700">
                          {formatToKRW(item.current_payable_amount)}원
                        </TDS.Typo>
                      </Td>
                      <Td isNumeric p="8.5px 24px">
                        <SettlementRevenue
                          size="sm"
                          color="grey.700"
                          revenue={item.current_profit_amount}
                          onClickDetail={() =>
                            onClickMonthRevenue(
                              previousDate,
                              item.character_profits,
                              item.current_profit_amount,
                            )
                          }
                        />
                      </Td>
                      <Td isNumeric>
                        <TDS.Typo size="sm" color="grey.700">
                          {formatToKRW(
                            item.current_profit_amount +
                              item.current_payable_amount,
                          )}
                          원
                        </TDS.Typo>
                      </Td>
                      <Td isNumeric>
                        <TDS.Typo size="sm" color="grey.700">
                          {formatToKRW(item.withdrawal_amount)}원
                        </TDS.Typo>
                      </Td>
                    </Tr>
                  );
                })}
              </>
            );
          })()}
        </Tbody>
      </Table>
    </TableContainer>
  );
}

function TdSkeleton() {
  return (
    <>
      <Td p="8.5px 24px">
        <Flex flexDirection="column">
          <Skeleton h={21} w={74} rounded={3} />
          <Skeleton h={18} w={130} rounded={3} />
        </Flex>
      </Td>
      <Td isNumeric>
        <Flex>
          <Spacer />
          <Skeleton h={17} w={78} rounded={3} />
        </Flex>
      </Td>
      <Td p="8.5px 24px">
        <Flex>
          <Spacer />
          <Skeleton h={17} w={78} rounded={3} />
        </Flex>
      </Td>
      <Td isNumeric>
        <Flex>
          <Spacer />
          <Skeleton h={17} w={78} rounded={3} />
        </Flex>
      </Td>
      <Td isNumeric>
        <Flex>
          <Spacer />
          <Skeleton h={17} w={78} rounded={3} />
        </Flex>
      </Td>
    </>
  );
}

import {
  Button,
  Divider,
  Flex,
  Grid,
  Text,
  Textarea,
  useToast,
} from '@chakra-ui/react';

import { requestGetGeneratedImage } from '@/api/image-generator';
import { Spinner } from '@/libs/chakra/components/Spinner';
import {
  FormDataType,
  IMAGE_GENERATE_PRESET_LIST,
  IMAGE_GENERATE_SELECT_MAP,
} from '@/pages/flux-poc-page/constants/image-generate-prompt';
import { isFailed, isSuccess } from '@/utils/guard';

import { ImageGenerateSelect } from './components/image-generate-select';

export default function FluxPocPage() {
  const [imageList, setImageList] = useState<string[]>([]);
  const [isGenerate, setIsGenerate] = useState(false);

  const toast = useToast();
  const onGenerate = async (formData: FormDataType, description: string) => {
    setIsGenerate(true);
    const prompt = [
      formData.gender,
      formData.age,
      formData.mood,
      formData.pose,
      formData.clothes,
      description,
    ]
      .filter(str => str !== '' && str !== 'None')
      .join(', ');

    const res = await requestGetGeneratedImage({ prompt });

    if (isSuccess(res)) {
      setImageList([...res.data, ...imageList]);
    }
    if (isFailed(res)) {
      const errorToast = (props: { description: string }) => {
        toast({
          title: 'Error',
          description: props.description,
          status: 'error',
          duration: 3000,
          position: 'top',
          isClosable: true,
        });
      };
      errorToast({
        description: '이미지 에러!',
      });
    }
    setIsGenerate(false);
  };

  return (
    <Flex alignItems="center" gap={12}>
      <GenerateImagePrompt onGenerate={onGenerate} isLoading={isGenerate} />
      <GeneratedImageContainer imageList={imageList} isGenerate={isGenerate} />
    </Flex>
  );
}

const GenerateImagePrompt = ({
  onGenerate,
  isLoading,
}: {
  onGenerate: (formData: FormDataType, description: string) => void;
  isLoading: boolean;
}) => {
  const [formData, setFormData] = useState<FormDataType>(
    Object.fromEntries(
      Object.entries(IMAGE_GENERATE_SELECT_MAP).map(([key, value]) => [
        key as keyof FormDataType,
        value.defaultValue,
      ]),
    ) as FormDataType,
  );
  const [description, setDescription] = useState('');

  const keyMap = Object.keys(formData) as (keyof FormDataType)[];

  const [presetIdx, setPresetIdx] = useState(0);
  const onUsePreset = () => {
    const { description: presetDescription, ...presetFormData } =
      IMAGE_GENERATE_PRESET_LIST[presetIdx];

    setPresetIdx(prevIdx => (prevIdx + 1) % IMAGE_GENERATE_PRESET_LIST.length);

    setFormData(presetFormData);
    setDescription(presetDescription);
    onGenerate(presetFormData, presetDescription);
  };

  return (
    <Flex rowGap={6} flexDir="column" alignItems="center">
      <h1 className="text-xl font-bold">캐릭터 이미지 생성기</h1>
      <Flex width={460} flexDir="column" rowGap={4}>
        <Flex gap={4}>
          {keyMap.slice(0, 3).map(key => (
            <Flex w="full" flexDir="column" rowGap={1} key={key}>
              <ImageGenerateSelect
                label={IMAGE_GENERATE_SELECT_MAP[key].label}
                options={IMAGE_GENERATE_SELECT_MAP[key].option}
                value={formData[key]}
                onSelect={value => {
                  setFormData({
                    ...formData,
                    [key]: value,
                  });
                }}
              />
            </Flex>
          ))}
        </Flex>

        <Flex gap={4}>
          {keyMap.slice(3, 5).map(key => (
            <Flex w="full" flexDir="column" rowGap={1} key={key}>
              <ImageGenerateSelect
                label={IMAGE_GENERATE_SELECT_MAP[key].label}
                options={IMAGE_GENERATE_SELECT_MAP[key].option}
                value={formData[key]}
                onSelect={value => {
                  setFormData({
                    ...formData,
                    [key]: value,
                  });
                }}
              />
            </Flex>
          ))}
        </Flex>

        <Divider my={2} />

        <Flex w="full" flexDir="column" rowGap={1}>
          <Text>자세한 묘사</Text>
          <Textarea
            bg="white"
            minH={20}
            maxH={290}
            placeholder={`작성 예시:\nLong blonde hair tied in a ponytail, wearing a bright pink sports top and leggings, Her eyes shine with enthusiasm`}
            value={description}
            onChange={e => setDescription(e.target.value)}
          />
        </Flex>
      </Flex>
      <Flex w="full" flexDir="column" rowGap={1}>
        <Button
          size="lg"
          colorScheme="orange"
          variant="solid"
          w="full"
          onClick={() => onGenerate(formData, description)}
          isLoading={isLoading}
          loadingText="생성중..."
          spinner={<></>}
        >
          생성하기
        </Button>
        <Button
          size="sm"
          colorScheme="gray"
          variant="ghost"
          w="full"
          onClick={onUsePreset}
          isDisabled={isLoading}
        >
          <Text fontSize={12} textDecorationLine="underline">
            예제 사용해서 생성해보기
          </Text>
        </Button>
      </Flex>
    </Flex>
  );
};

const GeneratedImageContainer = ({
  imageList,
  isGenerate,
}: {
  imageList: string[];
  isGenerate: boolean;
}) => {
  return (
    <Flex
      flexDir="column"
      bg="white"
      p={12}
      rowGap={4}
      alignItems="center"
      borderRadius={10}
      maxH="95vh"
      h="full"
      minW={519}
      minH={475}
    >
      {imageList.length === 0 && !isGenerate ? (
        <Flex
          justifyContent="center"
          alignItems="center"
          w="full"
          h="full"
          flexDir="column"
          rowGap={1}
        >
          <i className="text-6 i-t-background bg-grey-400" />
          <Text size="sm" fontSize={14}>
            생성된 이미지는 여기에 나타납니다.
          </Text>
        </Flex>
      ) : (
        <>
          <Text fontSize={14} whiteSpace="pre-wrap">
            {
              '생성된 이미지 중 마음에 드는것을 선택해주세요.\n마음에 드는 이미지가 없다면 다시 생성해주세요.'
            }
          </Text>
          <Grid templateColumns="repeat(3, 1fr)" gap={6} overflowY="scroll">
            {isGenerate &&
              Array.from({ length: 3 }).map((_, i) => (
                <Flex
                  key={i}
                  w={120}
                  h={160}
                  bg="grey.50"
                  justifyContent="center"
                  alignItems="center"
                >
                  <Spinner color="grey" />
                </Flex>
              ))}

            {imageList.map((imageUrl, i) => (
              <Flex
                key={i}
                w={120}
                h={160}
                bg="grey.50"
                justifyContent="center"
                alignItems="center"
                position="relative"
              >
                <Flex
                  justifyContent="center"
                  alignItems="center"
                  bg="blackAlpha.400"
                  w="full"
                  h="full"
                  position="absolute"
                  opacity={0}
                  className="hover:opacity-100"
                  justifyItems="center"
                  alignContent="center"
                >
                  <Button
                    size="sm"
                    variant="solid"
                    colorScheme="gray"
                    bg="gray.100"
                    as="a"
                    href={imageUrl}
                    target="_blank"
                  >
                    사용하기
                  </Button>
                </Flex>
                <img
                  src={imageUrl}
                  alt="Voicehub_Character"
                  className="w-full h-full"
                  key={imageUrl}
                />
              </Flex>
            ))}
          </Grid>
        </>
      )}
    </Flex>
  );
};

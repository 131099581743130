import { ConfigType, default as _dayjs } from 'dayjs';
import duration from 'dayjs/plugin/duration';

_dayjs.extend(duration);

export const dayjs = _dayjs;
export type DayjsType = ConfigType;

function getPreviousMonthStartAndEnd(date: Date | _dayjs.Dayjs) {
  const d = dayjs(date);
  const startDateOfPreviousMonth = d.subtract(1, 'month').startOf('month');
  const endDateOfPreviousMonth = d.subtract(1, 'month').endOf('month');

  function formatDateToKorean(date: _dayjs.Dayjs) {
    return dayjs(date).format('YYYY년 MM월 DD일');
  }

  return {
    startDate: formatDateToKorean(startDateOfPreviousMonth),
    endDate: formatDateToKorean(endDateOfPreviousMonth),
  };
}

export { getPreviousMonthStartAndEnd };

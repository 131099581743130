import axios, { AxiosError } from 'axios';

import { Failed, Success } from '@/types/result';

import {
  GetGeneratedImageRequestDTO,
  GetGeneratedImageResponseDTO,
} from './types/image-generator';

const baseURL = 'https://api.together.xyz/';
const TOKEN =
  'bbdb135e40c0edfb6d942b1d5db7e3085c228f395178b0750f8228a652449f79';

export const requestGetGeneratedImage = async (
  payload: GetGeneratedImageRequestDTO,
) => {
  try {
    const res: GetGeneratedImageResponseDTO = await axios({
      url: baseURL + 'v1/images/generations',
      method: 'post',
      headers: {
        accept: 'application/json',
        Authorization: `Bearer ${TOKEN}`,
        'Content-type': 'application/json',
      },
      data: {
        prompt:
          'anime, 100% white background, upper body, Clean lines and precise details, No distortion or irregularities, Realistic proportions, Neutral content with no political or religious references, ' +
          payload.prompt,
        model: 'black-forest-labs/FLUX.1-schnell',
        steps: 12,
        n: 3,
        height: 1200,
        width: 896,
      },
    });

    return {
      isFailed: false,
      data: res.data.data.map(data => data.url),
    } as Success<string[]>;
  } catch (err) {
    return {
      isFailed: true,
      error: err as AxiosError,
    } as Failed<AxiosError>;
  }
};

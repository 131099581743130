import type { ComponentProps } from 'react';

import { cn } from '@/utils';

type PocLayoutProps = ComponentProps<'main'>;

export function PocLayout({ className, children, ...props }: PocLayoutProps) {
  return (
    <main
      className={cn(
        'h-dvh font-sans font-normal leading-normal flex flex-col gap-6 justify-center place-items-center bg-background',
        'w-full',
        className,
      )}
      {...props}
    >
      {children}
    </main>
  );
}

import { Flex } from '@chakra-ui/react';

import { FormProvider, UseFormReturn } from 'react-hook-form';

import { z } from '@/utils/validator';

import { AccountFormSchema } from '../schema';
import { SettlementAccountNumberField } from './settlement-account-number-field';
import { SettlementAccountOwnerField } from './settlement-account-owner-field';
import { SettlementBankBookField } from './settlement-bank-book-field';
import { SettlementBankField } from './settlement-bank-field';
import { SettlementConfirmAccountNumberField } from './settlement-confirm-account-number-field';

type SettlementAccountFormProps = {
  form: UseFormReturn<z.infer<typeof AccountFormSchema>>;
};

export function SettlementAccountForm({ form }: SettlementAccountFormProps) {
  return (
    <>
      <Flex flexDirection="column" rowGap={3}>
        <FormProvider {...form}>
          <SettlementAccountOwnerField />
          <SettlementBankField />
          <SettlementAccountNumberField />
          <SettlementConfirmAccountNumberField />
          <SettlementBankBookField />
        </FormProvider>
      </Flex>
    </>
  );
}

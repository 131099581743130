import { dayjs } from '@/utils/time';

export const settleMonth = () => {
  const today = dayjs();
  const day = today.date();
  const month = today.month() + 1;
  const nextMonth = today.add(1, 'month').month() + 1;

  return day <= 14 ? month : nextMonth;
};

const IMAGE_GENERATE_GENDER_OPTIONS = [
  { label: '남성', value: 'male' },
  { label: '여성', value: 'female' },
  { label: '선택하지 않음', value: 'None' },
];

const IMAGE_GENERATE_AGE_OPTIONS = [
  { label: '아동', value: 'Child' },
  { label: '10대', value: 'Teenager' },
  { label: '20대', value: 'Yong Adult' },
  { label: '30대', value: 'Adult' },
  { label: '40대', value: 'Middle-aged' },
  { label: '50대', value: 'Senior' },
  { label: '60대 이상', value: 'Elderly' },
  { label: '선택하지 않음', value: 'None' },
];

const IMAGE_GENERATE_MOOD_OPTIONS = [
  { label: '기쁨', value: 'Joy' },
  { label: '슬픔', value: 'Sadness' },
  { label: '분노', value: 'Anger' },
  { label: '두려움', value: 'Fear' },
  { label: '놀라움', value: 'Surprise' },
  { label: '선택하지 않음', value: 'None' },
];

const IMAGE_GENERATE_POSE_OPTIONS = [
  { label: '정면', value: 'Straight forward' },
  { label: '팔짱 낀', value: 'Crossed arms' },
  { label: '앞으로 손을 모은', value: 'Hands folded' },
  { label: '주머니에 손을 넣은', value: 'Hands in pockets' },
  { label: '허리에 손을 올린', value: 'Hands on hips' },
  { label: '선택하지 않음', value: 'None' },
];

const IMAGE_GENERATE_CLOTHES_OPTIONS = [
  { label: '캐주얼', value: 'Casual' },
  { label: '정장', value: 'Formal' },
  { label: '운동복', value: 'Sportswear' },
  { label: '히어로 의상', value: 'Hero costume' },
  { label: '전통 의복', value: 'Cultural dress' },
  { label: '선택하지 않음', value: 'None' },
];

export type FormDataType = {
  gender: (typeof IMAGE_GENERATE_GENDER_OPTIONS)[number]['value'];
  age: (typeof IMAGE_GENERATE_AGE_OPTIONS)[number]['value'];
  mood: (typeof IMAGE_GENERATE_MOOD_OPTIONS)[number]['value'];
  pose: (typeof IMAGE_GENERATE_POSE_OPTIONS)[number]['value'];
  clothes: (typeof IMAGE_GENERATE_CLOTHES_OPTIONS)[number]['value'];
};

type OptionType = {
  label: string;
  value: string;
};

export const IMAGE_GENERATE_SELECT_MAP: {
  [key: (keyof FormDataType)[number]]: {
    label: string;
    option: OptionType[];
    defaultValue: string;
  };
} = {
  gender: {
    label: '성별',
    option: IMAGE_GENERATE_GENDER_OPTIONS,
    defaultValue: IMAGE_GENERATE_GENDER_OPTIONS[0].value,
  },
  age: {
    label: '연령대',
    option: IMAGE_GENERATE_AGE_OPTIONS,
    defaultValue: IMAGE_GENERATE_AGE_OPTIONS[0].value,
  },
  mood: {
    label: '분위기',
    option: IMAGE_GENERATE_MOOD_OPTIONS,
    defaultValue: IMAGE_GENERATE_MOOD_OPTIONS[0].value,
  },
  pose: {
    label: '포즈',
    option: IMAGE_GENERATE_POSE_OPTIONS,
    defaultValue: IMAGE_GENERATE_POSE_OPTIONS[0].value,
  },
  clothes: {
    label: '의상',
    option: IMAGE_GENERATE_CLOTHES_OPTIONS,
    defaultValue: IMAGE_GENERATE_CLOTHES_OPTIONS[0].value,
  },
} as const;

export const IMAGE_GENERATE_PRESET_LIST: (FormDataType & {
  description: string;
})[] = [
  {
    gender: IMAGE_GENERATE_GENDER_OPTIONS[0].value,
    age: IMAGE_GENERATE_AGE_OPTIONS[0].value,
    mood: IMAGE_GENERATE_MOOD_OPTIONS[4].value,
    pose: IMAGE_GENERATE_POSE_OPTIONS[4].value,
    clothes: IMAGE_GENERATE_CLOTHES_OPTIONS[2].value,
    description:
      'Big round eyes, a surprised expression, Wearing a blue hoodie and matching track pants, with short curly hair',
  },
  {
    gender: IMAGE_GENERATE_GENDER_OPTIONS[0].value,
    age: IMAGE_GENERATE_AGE_OPTIONS[2].value,
    mood: IMAGE_GENERATE_MOOD_OPTIONS[0].value,
    pose: IMAGE_GENERATE_POSE_OPTIONS[1].value,
    clothes: IMAGE_GENERATE_CLOTHES_OPTIONS[0].value,
    description:
      "A cheerful young man stands confidently with his arms crossed, radiating a warm, welcoming smile, His hair is short, with slightly messy strands giving him a carefree appearance, He's dressed in a casual red plaid shirt, the sleeves rolled up slightly to his forearms, paired with well-worn blue jeans that fit snugly, His light brown eyes sparkle under the soft light, and his sun-kissed skin suggests he spends plenty of time outdoors, The overall vibe is laid-back yet energetic, as if he’s ready to share a good joke or start a lively conversation",
  },
  {
    gender: IMAGE_GENERATE_GENDER_OPTIONS[1].value,
    age: IMAGE_GENERATE_AGE_OPTIONS[3].value,
    mood: IMAGE_GENERATE_MOOD_OPTIONS[1].value,
    pose: IMAGE_GENERATE_POSE_OPTIONS[0].value,
    clothes: IMAGE_GENERATE_CLOTHES_OPTIONS[1].value,
    description:
      'Tears stream down her face, Dark brown hair tied in a bun, wearing a black formal blazer and white blouse',
  },
  {
    gender: IMAGE_GENERATE_GENDER_OPTIONS[1].value,
    age: IMAGE_GENERATE_AGE_OPTIONS[4].value,
    mood: IMAGE_GENERATE_MOOD_OPTIONS[2].value,
    pose: IMAGE_GENERATE_POSE_OPTIONS[2].value,
    clothes: IMAGE_GENERATE_CLOTHES_OPTIONS[4].value,
    description:
      "An older woman with fierce determination etched across her face stands with her hands clasped behind her back, exuding an air of authority, Her silver hair is meticulously styled into a low, neat bun, with a few rebellious strands framing her stern expression, She is dressed in a traditional kimono, the silk fabric decorated with intricate floral designs in shades of red and gold, reflecting her heritage and strong sense of culture, Her piercing dark eyes reveal years of wisdom and experience, yet they burn with a fiery anger, as if she's confronting a great injustice, Her stance is rigid, embodying both strength and grace",
  },
  {
    gender: IMAGE_GENERATE_GENDER_OPTIONS[0].value,
    age: IMAGE_GENERATE_AGE_OPTIONS[1].value,
    mood: IMAGE_GENERATE_MOOD_OPTIONS[3].value,
    pose: IMAGE_GENERATE_POSE_OPTIONS[3].value,
    clothes: IMAGE_GENERATE_CLOTHES_OPTIONS[3].value,
    description:
      'Teenage boy, wide-eyed and cautious, Dressed in a sleek black superhero suit with silver details and a cape flowing behind him',
  },
  {
    gender: IMAGE_GENERATE_GENDER_OPTIONS[0].value,
    age: IMAGE_GENERATE_AGE_OPTIONS[4].value,
    mood: IMAGE_GENERATE_MOOD_OPTIONS[1].value,
    pose: IMAGE_GENERATE_POSE_OPTIONS[0].value,
    clothes: IMAGE_GENERATE_CLOTHES_OPTIONS[4].value,
    description:
      'A sorrowful man in his later years, his face lined with deep-set wrinkles that tell a story of hardships and time gone by, His grey hair is thin but neatly combed, and his eyes, once bright, now appear heavy with loss, He stands motionless, his posture slightly slouched as if the weight of the world rests on his shoulders, He is dressed in a beautifully crafted traditional suit, made from rich fabric with delicate embroidery running along the sleeves and collar, The suit, despite its elegance, seems secondary to the deep sadness in his expression, which conveys a profound sense of grief and longing, as though he has recently lost something or someone irreplaceable, His hands hang loosely by his sides, showing his resignation to the emotional burden he carries',
  },
  {
    gender: IMAGE_GENERATE_GENDER_OPTIONS[1].value,
    age: IMAGE_GENERATE_AGE_OPTIONS[2].value,
    mood: IMAGE_GENERATE_MOOD_OPTIONS[0].value,
    pose: IMAGE_GENERATE_POSE_OPTIONS[1].value,
    clothes: IMAGE_GENERATE_CLOTHES_OPTIONS[2].value,
    description:
      'Happy woman with long blonde hair tied in a ponytail, wearing a bright pink sports top and leggings, Her eyes shine with enthusiasm',
  },
  {
    gender: IMAGE_GENERATE_GENDER_OPTIONS[1].value,
    age: IMAGE_GENERATE_AGE_OPTIONS[2].value,
    mood: IMAGE_GENERATE_MOOD_OPTIONS[2].value,
    pose: IMAGE_GENERATE_POSE_OPTIONS[4].value,
    clothes: IMAGE_GENERATE_CLOTHES_OPTIONS[0].value,
    description:
      'Angry young woman, dark wavy hair falling over her shoulders, wearing a leather jacket and ripped jeans',
  },
  {
    gender: IMAGE_GENERATE_GENDER_OPTIONS[0].value,
    age: IMAGE_GENERATE_AGE_OPTIONS[3].value,
    mood: IMAGE_GENERATE_MOOD_OPTIONS[4].value,
    pose: IMAGE_GENERATE_POSE_OPTIONS[2].value,
    clothes: IMAGE_GENERATE_CLOTHES_OPTIONS[1].value,
    description:
      "Middle-aged man with slicked-back hair, wide-eyed and shocked, He's dressed in a crisp grey suit, no tie",
  },
  {
    gender: IMAGE_GENERATE_GENDER_OPTIONS[1].value,
    age: IMAGE_GENERATE_AGE_OPTIONS[0].value,
    mood: IMAGE_GENERATE_MOOD_OPTIONS[0].value,
    pose: IMAGE_GENERATE_POSE_OPTIONS[3].value,
    clothes: IMAGE_GENERATE_CLOTHES_OPTIONS[0].value,
    description:
      'A little girl with bright, curly hair wearing a yellow dress, hands in pockets, smiling widely',
  },
];
